var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "instruction-bg" }, [
    _c("div", { staticClass: "instruction-wrapper" }, [
      _c("div", { staticClass: "top-header-msg" }, [
        _vm._m(0),
        _c("div", { staticClass: "header-msg" }, [
          _vm._v(
            "\n        " + _vm._s(_vm.signupDirections.title) + "\n      "
          ),
        ]),
      ]),
      _c(
        "div",
        { staticClass: "wrap-information" },
        _vm._l(
          _vm.signupDirections.instructions,
          function (instruction, index) {
            return _c("div", { key: index, staticClass: "instruction-list" }, [
              _vm._m(1, true),
              _c("div", { staticClass: "instruction-msg" }, [
                _vm._v("\n          " + _vm._s(instruction) + "\n        "),
              ]),
            ])
          }
        ),
        0
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "icon" }, [
      _c("img", { attrs: { src: "/img/onboarding/rocket.svg", alt: "" } }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "icon" }, [
      _c("img", { attrs: { src: "/img/onboarding/green-mark.svg", alt: "" } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }