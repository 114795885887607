<template>
  <div>
    <Navbar />
    <div v-if="loading || isError" class="loader-container">
      <div class="loading-wrapper">
        <v-progress-linear
          value="3"
          height="3"
          indeterminate
          color="black"
        ></v-progress-linear>
      </div>
    </div>
    <div v-if="!loading && !isError" class="wrapper">
      <div class="signup_div">
        <div class="div_wrap">
          <!-- <div class="go-back-icon" @click="handleGoBack" v-if="!user">
            <img
              src="/img/onboarding/back-icon.svg"
              style="margin-right: 9px"
            />
            <span>Go Back</span>
          </div> -->
          <div v-if="user" class="welcome-msg">
            Welcome! You were invited by {{ user.teamLeadName }} to join
            {{ user.team_name }} on Grupa
          </div>
          <div v-if="userReferralCode">
            <div v-if="customerType == '1'" class="welcome-msg">
              You were invited by
              <span v-if="referralDetails">{{ referralDetails.name }}!</span>
              <br />
              <!-- Accept your invitation
               and earn up to $1,250 when you refer
              others. -->
            </div>
            <div v-if="customerType == '2'" class="welcome-msg">
              You were invited by
              <span v-if="referralDetails">{{ referralDetails.name }}!</span>
              <br />
              <!-- Accept your invitation and earn up to $1,250 when you refer
              others. -->
            </div>
          </div>
          <div class="page-img">
            <img src="/img/landing-page-image.svg" alt="" />
          </div>
          <div class="signup-options" @click="handleSessionStarted">
            <!-- Linkedin Signup for expert ONLY -->
            <!--  style="display:none" -->
            <div
              class="linkedin-signup"
              :style="customerType == '2' ? 'order:1' : ''"
            >
              <!-- <a :href="$linkedinRedirectURL"> -->
              <!--  :href="`mailto:matchOps@grupa.io?Subject=Interested in ${startup.name}`" -->
              <a
                :href="`https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=78rc3yunxe8cak&redirect_uri=${$Website}/linkedin-verify&state=${customerType}&scope=r_basicprofile%20r_emailaddress`"
              >
                <div class="connect-btn">
                  <img src="/img/onboarding/LinkedIn.svg" />
                  <span>Sign in with LinkedIn</span>
                </div>
              </a>
            </div>
            <div class="google-signup">
              <GoogleSignInNew
                :location="{ location }"
                :customerType="{ customerType }"
              />
            </div>

            <!-- <div class="google-signup">
              <GoogleSignIn />
            </div> -->
          </div>
        </div>
      </div>
      <SignupInstructions />
    </div>
    <AlertSuccess v-if="successMsg" time="7" />
    <AlertError v-if="errorMsg" time="7" />
  </div>
</template>

<script>
import Axios from "axios";
import { mapState, mapActions } from "vuex";
import Navbar from "@/components/Navbar/onboarding_navbar";
import SignupInstructions from "./partials/signupInstructions.vue";
import GoogleSignIn from "../googleLogin";
import GoogleSignInNew from "../googleLoginNew";

//
import mixPanel from "@/mixins/mixPanel";
import postLogin from "@/mixins/postLogin";
import AlertSuccess from "@/components/alertSuccess";
import AlertError from "@/components/alertError";
export default {
  components: {
    Navbar,
    SignupInstructions,
    AlertSuccess,
    AlertError,
    GoogleSignIn,
    GoogleSignInNew,
  },
  props: {
    user: {
      type: Object,
      default: null,
    },
  },
  mixins: [postLogin, mixPanel],
  data() {
    return {
      location: false,
      loading: false,
      evironment: process.env.NODE_ENV,
      userReferralCode: null,
      referralDetails: null,
      isError: false,
    };
  },
  mounted() {
    this.saveUserReferralCode();
    this.setCustomerParamsType();
  },
  watch: {
    location: function (newItem, oldItem) {
      if (newItem === true) {
        setTimeout(() => {
          document.location.href = `${process.env.VUE_APP_BACKEND_API}/auth/google`;
        }, 0);
      }
    },
  },
  methods: {
    ...mapActions("loaderManagement", ["setAuthenticatedLoader"]),
    ...mapActions("userManagement", [
      "setAutheticatedUser",
      "setUserReferralCode",
      "setLinkedinLogin",
      "setCustomerType",
    ]),
    ...mapActions("devOnboarding", [
      "setLinkedinProfile",
      "setRegistrationToken",
    ]),
    ...mapActions("alertMsg", ["setSuccessMsg", "setErrorMsg"]),
    saveUserReferralCode() {
      if (this.$route.query.ref) {
        let code = this.$route.query.ref;
        this.userReferralCode = code;
        this.setUserReferralCode(code);
        this.confirmReferralCode(code);
      }
    },
    async confirmReferralCode(code) {
      this.loading = true;
      await Axios.get(this.$baseUrl + `/get-referral/name/${code}`)
        .then((response) => {
          if (response.data.status == 1) {
            const { type } = response.data.data;
            this.referralDetails = response.data.data;
            let customerType = type == "founder" ? 2 : 1;
            this.setCustomerType(customerType);
            this.mixPanelTrack({
              event: "ClickReferralCode",
              email: null,
              message: "Successful",
              referralCode: code,
            });
          }
        })
        .catch((error) => {})
        .finally(() => {
          this.loading = false;
        });
    },

    
    async updateUserType(type) {
      this.loader = true;
      // alert(type)
      await changeUserType(type)
        .then((response) => {
          //console.log(response)
          if (response.data.status == 1) {
            const { user, nylas } = response.data.data;
            //console.log(this.$router)

           // console.log("User Type", nylas);
            this.updateLoginUser(user);
            if (type == 1) {
              this.$router.push({ name: "linkedin_profile" });
            } else {
              this.$router.push({ name: "start_project" });
            }
          }
        })
        .catch((error) => {})
        .finally(() => {
          this.loader = false;
        });
    },
    setCustomerParamsType() {
      if (this.$route.query.type) {
        let userType = this.$route.query.type;
        let location = this.$route.query.loc;
        if (location.length > 0) {
          if (location === "google") {
            this.location = true;
          }
        }
        let customerType =
          userType === "founder" ? 2 : userType === "expert" ? 1 : "";

        this.setCustomerType(customerType);
      } else {
        if (this.customerType == "" || this.customerType === null) {
          //this.isError = true;
          // console.log(this.$Website + "-" + this.customerType);

          /* this.setErrorMsg(
            "User category not detected. Kindly choose if you are a founder or an engineer to continue"
          ); */

         /*  alert(
            "User category not detected. \nKindly choose if you are a founder or an engineer to continue"
          );
          document.location.href = `${this.$Website}`; */
        } else {
          //  console.log(this.$Website + "-" + this.customerType);
          this.setCustomerType(this.customerType);
        }
      }
    },
    handleSessionStarted() {
      this.mixPanelTrack({ event: "SessionStarted", email: null });
    },
    handleGoBack() {
      let query;
      if (this.$route.query.ref) {
        query = this.$route.query.ref;
      }
      this.$router.push({ name: "signup", query: { ref: query } });
    },
    handleGoogleSuccess(response) {
      this.setAuthenticatedLoader(true);
      if (response) {
        const userDetails = response.getBasicProfile();
        const displayName = userDetails.getName();
        const email = userDetails.getEmail();
        const fullName = displayName.split("\\s+")[0];
        let firstName = fullName.split(" ")[0];
        let lastName = fullName.split(" ")[1];
        firstName = firstName ? firstName : fullName;
        lastName = lastName ? lastName : fullName;
        let payload = { firstName, lastName, email };
        // User without token
        if (!this.registrationToken) {
          this.handleCreateUser(payload);
        }
        // Joining a team with token
        if (this.registrationToken) {
          this.handleJoinTeam(payload);
        }
      }
    },
    async handleCreateUser(payload) {
      let referralCode = this.referralCode;
      let user_type = this.customerType;
      if (referralCode) {
        payload.referralCode = referralCode;
      }
      payload.user_type = user_type;
      await Axios.post(this.$baseUrl + `/onboarding/google/sign_in`, payload)
        .then((response) => {
          if (response.data.status == 1) {
            let { user, token, team } = response.data.data;
            user.token = token;
            this.setLogInUser(user);
            if (user.user_type == 1) {
              let payloadData = { data: null, profileUrl: null };
              // clear saved linkedinProfile
              this.setLinkedinProfile(payloadData);
              if (team) {
                this.teamActivePage(user, team);
              } else {
                // if (user.activation_level >= 2) {
                //   this.$router.push({ name: "team_option" });
                // } else {
                //   this.$router.push({ name: "linkedin_profile" });
                // }
                this.updateTeam(token, user);
              }
            } else if (
              user.user_type == 2 ||
              user.user_type == 5 ||
              !user.user_type
            ) {
              this.$router.push({ name: "user_type" });
            }
            this.mixPanelTrack({
              event: "GoogleSignIn",
              email: user.email,
              userType:
                user.user_type == 1
                  ? "dev"
                  : user.user_type == 2
                  ? "client"
                  : "Not Selected",
            });
            this.setUserReferralCode(null);
          } else {
            this.mixPanelTrack({
              event: "GoogleSignInEndpointFailed",
              email: null,
              message: "Failed to create user",
            });
            this.$router.push({ name: "sign_up" });
          }
        })
        .catch((error) => {
          this.mixPanelTrack({
            event: "GoogleSignInEndpointFailed",
            email: null,
            message: "Failed to create user",
          });
        });
    },
    async handleJoinTeam(payload) {
      const link = this.registrationToken;
      await Axios.post(
        this.$baseUrl + `/dev/onboarding/google/sign_in_invited/${link}`,
        payload
      )
        .then((response) => {
          if (response.data.status == 1) {
            let { user, token, team } = response.data.data;
            user.token = token;
            this.setLogInUser(user);
            if (user.user_type == 1) {
              if (team && user) {
                this.teamActivePage(user, team);
                this.setRegistrationToken(null);
              } else {
                this.$router.push({
                  name: "join_team",
                  params: { reg_token: link },
                });
              }
            } else {
            }
            this.$Mixpanel.track("GoogleSignInJoinTeam", {
              distinct_id: user.email,
            });
          } else {
            // this.setErrorMsg("User Authetication Failed");
            // this.$router.push({
            //   name: "join_team",
            //   params: { reg_token: link },
            // });
          }
        })
        .catch((error) => {
          // this.setErrorMsg("User Authetication Failed");
          // this.$router.push({
          //   name: "join_team",
          //   params: { reg_token: link },
          // });
        });
    },
    setLogInUser(user) {
      let payload = {
        user,
        isAuthenticated: true,
        userType: user.user_type,
      };
      localStorage.setItem("grupa_user", JSON.stringify(user));
      this.setAutheticatedUser(payload);
    },
    handleLinkedAuth() {
      this.setLinkedinLogin("teamLead");
    },
    updateTeam(token, user) {
      const header = {
        headers: { Authorization: `Bearer ${token}` },
      };
      Axios.get(this.$baseUrl + `/onboarding/set/usertype/1`, header)
        .then((response) => {
          if (response.data.status == 1) {
            const { user } = response.data.data;
            this.updateLoginUser(user);
            if (user.activation_level >= 2) {
              this.$router.push({ name: "team_option" });
            } else {
              this.$router.push({ name: "linkedin_profile" });
            }
          }
        })
        .catch((error) => {});
    },
    updateLoginUser(user) {
      let payload = {
        user,
        isAuthenticated: true,
        userType: user.user_type,
      };
      this.setAutheticatedUser(payload);
    },
  },
  computed: {
    ...mapState("devOnboarding", ["registrationToken"]),
    ...mapState("alertMsg", ["successMsg", "errorMsg"]),
    ...mapState("userManagement", [
      "currentUser",
      "referralCode",
      "customerType",
    ]),
  },
};
</script>
<style scoped>
* {
  font-family: Moderat;
  font-style: normal;
  font-weight: normal;
}
.loader-container {
  height: calc(100vh - 80px);
  display: flex;
  justify-content: center;
  align-items: center;
}
.loading-wrapper {
  width: 400px;
}
.wrapper {
  display: flex;
  flex-wrap: wrap;
}
.go-back-icon {
  cursor: pointer;
  text-align: left;
}
.go-back-icon span {
  color: #0055a5;
  font-size: 14px;
  font-family: Moderat;
  font-style: normal;
  font-weight: 500;
}
.signup_div {
  background-color: #ffffff;
  width: 65%;
  display: flex;
  justify-content: center;
}
.welcome-msg {
  font-weight: normal;
  font-size: 14px;
  line-height: 130%;
  color: #1fa564;
  text-align: center;
}
.welcome-msg span {
  font-weight: bold;
}
.div_wrap {
  margin-top: 48px;
  width: 410px;
}
.login_div {
  margin-top: 16px;
  font-size: 14px;
  line-height: 150%;
  color: #15171a;
  text-align: right;
}
.page-img {
  margin-top: 67px;
  height: 217px;
}
/* Signup Options Linkedin */
.signup-options {
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
a,
.signup-options a {
  text-decoration: none;
}
@media (max-width: 768px) {
  .signup_div {
    width: 100%;
    padding-right: 32px;
    padding-left: 32px;
  }
  .login_div {
    margin-top: 16px;
  }
  .div-left {
    width: 100%;
    order: -1;
  }
}
.connect-btn {
  background: #0288d1;
  box-shadow: 1px 2px 5px rgba(21, 23, 26, 0.2);
  border-radius: 4px;
  padding: 9px;
  width: 410px;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #ffffff;
  cursor: pointer;
}
.connect-btn img {
  margin-right: 80px;
}
.linkedin-signup {
  margin-top: 48px;
}
@media (max-width: 768px) {
  .div_wrap {
    width: 100%;
    padding-left: 32px;
    padding-right: 32px;
  }
}
</style>
