<template>
  <div>
    <!--  `/connect/google/calendar/authenticate_client/` -->
    <a
      :href="
        location.location
          ? '#'
          : $baseUrl +
            `/auth/google?user_type=${
              customerType.customerType === 2 ? 'clients' : 'engineer'
            }`
      "
    >
      <div class="google-signup-btn">
        <img
          v-if="!location.location"
          src="/img/onboarding/google-icon.svg"
          alt="Sync Your Google Profile"
        />

        <!--  <img
          src="/img/google calendar.svg"
          alt="Sync Your Google Calendar"
          style="width: 30px; margin-left: 10px"
        /> -->
        <div class="google-text">
          <img
            src="/img/lightbox/preloader.gif"
            style="height: 30px; margin-top: 7px"
            v-if="location.location"
          />
          <span
            v-if="location.location"
            style="font-size: 12px; padding-top: 15px; padding-left: 10px"
            >login you in with google...</span
          >
          <div v-if="!location.location">Sign-in with Google</div>
        </div>
      </div>
    </a>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  props: {
    location: {
      type: Object,
      default: false,
    },
    customerType: {
      type: Object,
    },
  },
  data: () => ({
    params: {
      client_id:
        "474342549141-f938ugmanf03f71fdq2j8t3iom3a6s70.apps.googleusercontent.com",
      // redirect_uri: "http://localhost:8080/",
      ux_mode: "popup",
    },
    renderParams: {
      width: 250,
      height: 50,
      longtitle: true,
    },
  }),
  created() {},
  methods: {
    ...mapActions("userManagement", ["setGoogleSignInData"]),
    ...mapActions("alertMsg", ["setSuccessMsg", "setErrorMsg"]),
  },
  computed: {},
};
</script>
<style scoped>
* {
  font-family: Moderat;
  font-style: normal;
  font-weight: normal;
  /* #E4E5E7 */
}
a {
  text-decoration: none;
}
</style>
