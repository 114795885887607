var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "a",
      {
        attrs: {
          href: _vm.location.location
            ? "#"
            : _vm.$baseUrl +
              "/auth/google?user_type=" +
              (_vm.customerType.customerType === 2 ? "clients" : "engineer"),
        },
      },
      [
        _c("div", { staticClass: "google-signup-btn" }, [
          !_vm.location.location
            ? _c("img", {
                attrs: {
                  src: "/img/onboarding/google-icon.svg",
                  alt: "Sync Your Google Profile",
                },
              })
            : _vm._e(),
          _c("div", { staticClass: "google-text" }, [
            _vm.location.location
              ? _c("img", {
                  staticStyle: { height: "30px", "margin-top": "7px" },
                  attrs: { src: "/img/lightbox/preloader.gif" },
                })
              : _vm._e(),
            _vm.location.location
              ? _c(
                  "span",
                  {
                    staticStyle: {
                      "font-size": "12px",
                      "padding-top": "15px",
                      "padding-left": "10px",
                    },
                  },
                  [_vm._v("login you in with google...")]
                )
              : _vm._e(),
            !_vm.location.location
              ? _c("div", [_vm._v("Sign-in with Google")])
              : _vm._e(),
          ]),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }