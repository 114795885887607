var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("Navbar"),
      _vm.loading || _vm.isError
        ? _c("div", { staticClass: "loader-container" }, [
            _c(
              "div",
              { staticClass: "loading-wrapper" },
              [
                _c("v-progress-linear", {
                  attrs: {
                    value: "3",
                    height: "3",
                    indeterminate: "",
                    color: "black",
                  },
                }),
              ],
              1
            ),
          ])
        : _vm._e(),
      !_vm.loading && !_vm.isError
        ? _c(
            "div",
            { staticClass: "wrapper" },
            [
              _c("div", { staticClass: "signup_div" }, [
                _c("div", { staticClass: "div_wrap" }, [
                  _vm.user
                    ? _c("div", { staticClass: "welcome-msg" }, [
                        _vm._v(
                          "\n          Welcome! You were invited by " +
                            _vm._s(_vm.user.teamLeadName) +
                            " to join\n          " +
                            _vm._s(_vm.user.team_name) +
                            " on Grupa\n        "
                        ),
                      ])
                    : _vm._e(),
                  _vm.userReferralCode
                    ? _c("div", [
                        _vm.customerType == "1"
                          ? _c("div", { staticClass: "welcome-msg" }, [
                              _vm._v(
                                "\n            You were invited by\n            "
                              ),
                              _vm.referralDetails
                                ? _c("span", [
                                    _vm._v(
                                      _vm._s(_vm.referralDetails.name) + "!"
                                    ),
                                  ])
                                : _vm._e(),
                              _c("br"),
                            ])
                          : _vm._e(),
                        _vm.customerType == "2"
                          ? _c("div", { staticClass: "welcome-msg" }, [
                              _vm._v(
                                "\n            You were invited by\n            "
                              ),
                              _vm.referralDetails
                                ? _c("span", [
                                    _vm._v(
                                      _vm._s(_vm.referralDetails.name) + "!"
                                    ),
                                  ])
                                : _vm._e(),
                              _c("br"),
                            ])
                          : _vm._e(),
                      ])
                    : _vm._e(),
                  _vm._m(0),
                  _c(
                    "div",
                    {
                      staticClass: "signup-options",
                      on: { click: _vm.handleSessionStarted },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "linkedin-signup",
                          style: _vm.customerType == "2" ? "order:1" : "",
                        },
                        [
                          _c(
                            "a",
                            {
                              attrs: {
                                href:
                                  "https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=78rc3yunxe8cak&redirect_uri=" +
                                  _vm.$Website +
                                  "/linkedin-verify&state=" +
                                  _vm.customerType +
                                  "&scope=r_basicprofile%20r_emailaddress",
                              },
                            },
                            [_vm._m(1)]
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "google-signup" },
                        [
                          _c("GoogleSignInNew", {
                            attrs: {
                              location: { location: _vm.location },
                              customerType: { customerType: _vm.customerType },
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                ]),
              ]),
              _c("SignupInstructions"),
            ],
            1
          )
        : _vm._e(),
      _vm.successMsg ? _c("AlertSuccess", { attrs: { time: "7" } }) : _vm._e(),
      _vm.errorMsg ? _c("AlertError", { attrs: { time: "7" } }) : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "page-img" }, [
      _c("img", { attrs: { src: "/img/landing-page-image.svg", alt: "" } }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "connect-btn" }, [
      _c("img", { attrs: { src: "/img/onboarding/LinkedIn.svg" } }),
      _c("span", [_vm._v("Sign in with LinkedIn")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }