<template>
  <div class="instruction-bg">
    <div class="instruction-wrapper">
      <div class="top-header-msg">
        <div class="icon">
          <img src="/img/onboarding/rocket.svg" alt="" />
        </div>
        <div class="header-msg">
          {{ signupDirections.title }}
        </div>
      </div>
      <div class="wrap-information">
        <div
          class="instruction-list"
          v-for="(instruction, index) in signupDirections.instructions"
          :key="index"
        >
          <div class="icon">
            <img src="/img/onboarding/green-mark.svg" alt="" />
          </div>
          <div class="instruction-msg">
            {{ instruction }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  components: {},

  data: () => ({
    teamTitle: `Build the next big thing with innovative startups!`,
    teamSignUp: {
      title: "Build the next big thing with innovative startups!",
      instructions: [
        "Bored at FAANG? Work on exciting, innovative and interesting products with venture backed startups.",
        "Invest your expertise, skill and experience to help scale the next breakout companies.",
        "Work on the side as consultants/advisors.",
        "Get compensated in equity and/or cash: to invest in the long term success of these companies.",
        "Test the waters, before you potentially decide to join the next rocket ship.",
      ],
    },
    clientSignup: {
      title: "Work with the Best Product Engineering Teams in the world.",
      instructions: [
        "Work with Silicon Valley product engineering teams from FAANG companies",
        "Don’t want to outsource? Get embedded teams who integrate with your company, culture & work systems.",
        "Compensate teams in equity and/or cash: to get them aligned on the long-term vision on the company.",
        "Get full transparency on the production process with our integrated collaboration tool",
        "The teams work with you in their spare time, with the potential to join you as full-time employees.",
      ],
    },
  }),

  created() {},
  methods: {},
  computed: {
    ...mapState("userManagement", ["customerType"]),
    signupDirections() {
      if (this.customerType == 1) {
        return this.teamSignUp;
      } else if (this.customerType == 2) {
        return this.clientSignup;
      } else if (this.customerType == null) {
        return this.teamSignUp;
      }
    },
  },
};
</script>
<style scoped>
* {
  font-family: Moderat;
  font-style: normal;
  font-weight: normal;
}
.instruction-bg {
  background: #f7f7f8;
  width: 35%;
  padding-top: 64px;
  height: calc(100vh - 80px);
  display: flex;
  justify-content: center;
}

.instruction-wrapper {
  width: 458px;
}
.instruction-list,
.top-header-msg {
  display: flex;
}
.instruction-list .icon,
.top-header-msg .icon {
  margin-right: 16px;
}
.instruction-list {
  margin-top: 24px;
}
.header-msg {
  font-size: 23px;
  line-height: 28px;
  display: flex;
  align-items: center;
  width: 418px;
  color: #1b1e22;
  text-align: left;
}
.instruction-msg {
  width: 418px;
  font-family: Moderat;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 130%;
  color: #53585f;
  text-align: left;
}
.wrap-information {
  margin-top: 48px;
}
@media (max-width: 768px) {
  .instruction-bg {
    width: 100%;
    order: -1;
    height: initial;
    padding-bottom: 24px;
  }
  .header-msg {
    font-size: 20px;
    width: 400px;
  }
  .instruction-msg {
    width: 400px;
  }
  .instruction-wrapper {
    width: 418px;
  }
}
@media (max-width: 1440px) {
  .header-msg {
    font-size: 20px;
    width: 400px;
  }
  .instruction-wrapper {
    width: 418px;
  }
}
@media (max-width: 1280px) {
  .header-msg {
    font-size: 20px;
    width: 400px;
  }
  .instruction-wrapper {
    width: 418px;
    padding: 0px 24px;
  }
}
</style>
